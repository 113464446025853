import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import styles from "../css/main.module.css";
import Spline from "@splinetool/react-spline";
import useWindowDimensions from "./helper";

const DarkMatter = () => {
  const model = useRef();
  const [modelLoaded, setModelLoaded] = useState(false);
  const { height, width } = useWindowDimensions();

  function onLoad(spline) {
    const obj = spline.findObjectByName("matter");
    model.current = obj;
    if (obj) {
      setModelLoaded(true);
    }
  }

  useEffect(() => {
    if (modelLoaded) {
      if (width < 600 && model.current != null) {
        model.current.scale.x = 0.7;
        model.current.scale.y = 0.7;
        model.current.scale.z = 0.7;
        model.current.position.x = 10;
        model.current.position.y = 20;
      }else{
        model.current.scale.x = 1;
        model.current.scale.y = 1;
        model.current.scale.z = 1;
        model.current.position.x = -60;
      }
    }
  }, [modelLoaded, width]);

  return (
    <div className={styles.modelWrapper}>
      <Spline
        className={styles.Model}
        onLoad={onLoad}
        scene="https://prod.spline.design/zyvry2ZLsxieWBwb/scene.splinecode"
      />
    </div>
  );
};

export default DarkMatter;
